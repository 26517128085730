<template>
    <!-- 相机租赁详情路由 -->
    <div class="camera_detail_box">
        <div class="container">
            <!-- 面包屑导航 -->
            <div class="breadcrumb_box">
                <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
            </div>
            <!-- 商品详情 -->
            <camera-detail-info></camera-detail-info>
            <!-- tabs -->
            <detail-tabs
                :detail-content="info.detail">
            </detail-tabs>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    components:{
        breadCrumb: ()=> import('@/components/breadCrumb'),
        cameraDetailInfo: ()=> import('@/components/cameraDetailInfo'),
        detailTabs: ()=> import('@/components/detailTabs'),
    },
    data() {
        return {
            // 面包屑导航数据
            breadcrumb: [
                {
                    path: "/clothes",
                    path_name: "租赁共享",
                },
                {
                    path: "detail",
                    path_name: "详情",
                },
            ],
        }
    },
    computed: mapState({
        info: state => state.goodsDetail.info
    }),
    methods: {
        ...mapMutations([
            "clearInfo",
        ]),
        ...mapActions([
            "getCameraDetail",
        ])
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getCameraDetail(to.query.id);
        });
    },
    beforeRouteLeave (to, from, next) {
        this.clearInfo();
        next();
    }
}
</script>

<style scoped>
/* 相机租赁详情页盒子 */
.camera_detail_box{
    background-color: #f5f5f5;
}
/* 相机租赁面包屑导航盒子 */
.breadcrumb_box{
    margin: 20px 0;
}
</style>
